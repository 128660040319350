$unit: 48px;

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: $unit / 4 !important;
}

.gap-2 {
  gap: $unit / 2 !important;
}

.gap-3 {
  gap: $unit * 0.75 !important;
}

.gap-4 {
  gap: $unit !important;
}
