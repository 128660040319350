.mw-440 {
  max-width: 440px;
  margin: auto;
}

.mw-380 {
  max-width: 380px;
  margin: auto;
}

.mw-880 {
  max-width: 880px;
  margin: auto;
}

.mw-1200 {
  max-width: 1200px;
  margin: auto;
}

.mw-1 {
  max-width: 100%;
}

.width-auto {
  width: auto;
}
.width-0 {
  width: 0px;
}
.width-16 {
  width: 16.666667%;
} // 1/6
.width-20 {
  width: 20%;
} // 1/5
.width-25 {
  width: 25%;
} // 1/4
.width-33 {
  width: 33.333333%;
} // 1/3, 2/6
.width-40 {
  width: 40%;
} // 2/5
.width-50 {
  width: 50%;
} // 1/2, 2/4, 3/6
.width-60 {
  width: 60%;
} // 3/5
.width-66 {
  width: 66.666667%;
} // 2/3, 4/6
.width-75 {
  width: 75%;
} // 3/4
.width-80 {
  width: 80%;
} // 4/5
.width-83 {
  width: 83.333333%;
} // 5/6
.width-full {
  width: 100%;
}
.width-full-sidenav {
  max-width: calc(100vw - 196px) !important;
} //with 48 padding on each side

.min-width-0 {
  min-width: 0px;
}
.min-width-16 {
  min-width: 16.666667%;
} // 1/6
.min-width-20 {
  min-width: 20%;
} // 1/5
.min-width-25 {
  min-width: 25%;
} // 1/4
.min-width-33 {
  min-width: 33.333333%;
} // 1/3, 2/6
.min-width-40 {
  min-width: 40%;
} // 2/5
.min-width-50 {
  min-width: 50%;
} // 1/2, 2/4, 3/6
.min-width-60 {
  min-width: 60%;
} // 3/5
.min-width-66 {
  min-width: 66.666667%;
} // 2/3, 4/6
.min-width-75 {
  min-width: 75%;
} // 3/4
.min-width-80 {
  min-width: 80%;
} // 4/5
.min-width-83 {
  min-width: 83.333333%;
} // 5/6
.min-width-full {
  min-width: 100%;
}

.auto-x {
  overflow-x: auto;
}

.basis-auto {
  flex-basis: auto;
}
.basis-0 {
  flex-basis: 0px;
}
.basis-16 {
  flex-basis: 16.666667%;
} // 1/6
.basis-20 {
  flex-basis: 20%;
} // 1/5
.basis-25 {
  flex-basis: 25%;
} // 1/4
.basis-33 {
  flex-basis: 33.333333%;
} // 1/3, 2/6
.basis-40 {
  flex-basis: 40%;
} // 2/5
.basis-50 {
  flex-basis: 50%;
} // 1/2, 2/4, 3/6
.basis-60 {
  flex-basis: 60%;
} // 3/5
.basis-66 {
  flex-basis: 66.666667%;
} // 2/3, 4/6
.basis-75 {
  flex-basis: 75%;
} // 3/4
.basis-80 {
  flex-basis: 80%;
} // 4/5
.basis-83 {
  flex-basis: 83.333333%;
} // 5/6
