$unit: 48px;

.p-0 {
  padding: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: $unit / 4 !important;
}

.px-1 {
  padding-left: $unit / 4 !important;
  padding-right: $unit / 4 !important;
}

.py-1 {
  padding-bottom: $unit / 4 !important;
  padding-top: $unit / 4 !important;
}

.pt-1 {
  padding-top: $unit / 4 !important;
}

.pr-1 {
  padding-right: $unit / 4 !important;
}

.pb-1 {
  padding-bottom: $unit / 4 !important;
}

.pl-1 {
  padding-left: $unit / 4 !important;
}

.p-2 {
  padding: $unit / 2 !important;
}

.px-2 {
  padding-left: $unit / 2 !important;
  padding-right: $unit / 2 !important;
}

.py-2 {
  padding-bottom: $unit / 2 !important;
  padding-top: $unit / 2 !important;
}

.pt-2 {
  padding-top: $unit / 2 !important;
}

.pr-2 {
  padding-right: $unit / 2 !important;
}

.pb-2 {
  padding-bottom: $unit / 2 !important;
}

.pl-2 {
  padding-left: $unit / 2 !important;
}

.p-3 {
  padding: $unit * 0.75 !important;
}

.px-3 {
  padding-left: $unit * 0.75 !important;
  padding-right: $unit * 0.75 !important;
}

.py-3 {
  padding-bottom: $unit * 0.75 !important;
  padding-top: $unit * 0.75 !important;
}

.pt-3 {
  padding-top: $unit * 0.75 !important;
}

.pr-3 {
  padding-right: $unit * 0.75 !important;
}

.pb-3 {
  padding-bottom: $unit * 0.75 !important;
}

.pl-3 {
  padding-left: $unit * 0.75 !important;
}

.p-4 {
  padding: $unit !important;
}

.px-4 {
  padding-left: $unit !important;
  padding-right: $unit !important;
}

.py-4 {
  padding-bottom: $unit !important;
  padding-top: $unit !important;
}

.pt-4 {
  padding-top: $unit !important;
}

.pr-4 {
  padding-right: $unit !important;
}

.pb-4 {
  padding-bottom: $unit !important;
}

.pl-4 {
  padding-left: $unit !important;
}
