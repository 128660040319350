.basis-auto	{ flex-basis: auto; }
.basis-0	{ flex-basis: 0px; }
.basis-16	{flex-basis: 16.666667%;} // 1/6
.basis-20	{flex-basis: 20%;} // 1/5
.basis-25	{flex-basis: 25%;} // 1/4
.basis-33	{flex-basis: 33.333333%;} // 1/3, 2/6
.basis-40	{flex-basis: 40%;} // 2/5
.basis-50	{flex-basis: 50%;} // 1/2, 2/4, 3/6
.basis-60	{flex-basis: 60%; } // 3/5
.basis-66	{flex-basis: 66.666667%;} // 2/3, 4/6
.basis-75	{flex-basis: 75%;} // 3/4
.basis-80	{flex-basis: 80%;} // 4/5
.basis-83	{flex-basis: 83.333333%;} // 5/6
.basis-full	{ flex-basis: 100%; }

.flex { min-width: 0 !important; display: flex; }
.flex-1	{ min-width: 0 !important; flex: 1 1 0%; }
.flex-auto	{ min-width: 0 !important; flex: 1 1 auto; }
.flex-initial	{ min-width: 0 !important; flex: 0 1 auto; }
.flex-none	{ min-width: 0 !important; flex: none; }

// don't use this class... it's scrap!
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  a,
  img {
    display: block !important;
  }

  &.center {
    justify-content: center;
  }

  &.center {
    justify-content: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.stretch {
    justify-content: stretch;
  }
}

.row {
  &.wrap {
    flex-wrap: wrap;
  }
}
