@import './configs/colors';

.mat-form-field {
    [readonly] {
        color: $dark-disabled-text;
    }
}

mat-form-field.mat-form-field.slender-form-field > .mat-form-field-wrapper {
    margin: 0 !important;
    padding: 0 !important;
    border-width: 0 !important;
    // position: relative;
    // top: 0.625em;
}

mat-form-field.mat-form-field.slender-form-field-infix {
    .mat-form-field-infix {
        padding: 0 !important;
        margin: 0 !important;
        border-width: 0 !important;
    }
}

mat-form-field.white-form-field .mat-form-field-wrapper {
    background-color: #fff !important;
    border-radius: 5px !important;
    color: $black;
}