$unit: 48px;

.m-0 {
  margin: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: $unit / 4 !important;
}

.mx-1 {
  margin-left: $unit / 4 !important;
  margin-right: $unit / 4 !important;
}

.my-1 {
  margin-bottom: $unit / 4 !important;
  margin-top: $unit / 4 !important;
}

.mt-1 {
  margin-top: $unit / 4 !important;
}

.mr-1 {
  margin-right: $unit / 4 !important;
}

.mb-1 {
  margin-bottom: $unit / 4 !important;
}

.ml-1 {
  margin-left: $unit / 4 !important;
}

.m-2 {
  margin: $unit / 2 !important;
}

.mx-2 {
  margin-left: $unit / 2 !important;
  margin-right: $unit / 2 !important;
}

.my-2 {
  margin-bottom: $unit / 2 !important;
  margin-top: $unit / 2 !important;
}

.mt-2 {
  margin-top: $unit / 2 !important;
}

.mr-2 {
  margin-right: $unit / 2 !important;
}

.mb-2 {
  margin-bottom: $unit / 2 !important;
}

.ml-2 {
  margin-left: $unit / 2 !important;
}

.m-3 {
  margin: $unit * 0.75 !important;
}

.mx-3 {
  margin-left: $unit * 0.75 !important;
  margin-right: $unit * 0.75 !important;
}

.my-3 {
  margin-bottom: $unit * 0.75 !important;
  margin-top: $unit * 0.75 !important;
}

.mt-3 {
  margin-top: $unit * 0.75 !important;
}

.mr-3 {
  margin-right: $unit * 0.75 !important;
}

.mb-3 {
  margin-bottom: $unit * 0.75 !important;
}

.ml-3 {
  margin-left: $unit * 0.75 !important;
}

.m-4 {
  margin: $unit !important;
}

.mx-4 {
  margin-left: $unit !important;
  margin-right: $unit !important;
}

.my-4 {
  margin-bottom: $unit !important;
  margin-top: $unit !important;
}

.mt-4 {
  margin-top: $unit !important;
}

.mr-4 {
  margin-right: $unit !important;
}

.mb-4 {
  margin-bottom: $unit !important;
}

.ml-4 {
  margin-left: $unit !important;
}

.ml-8 {
  margin-left: $unit * 2 !important;
}

.mt-50 {
  margin-top: 50%;
}
