// https://tailwindcss.com/docs/flex

// direction
.flex-row	{ flex-direction: row; }
.flex-row-reverse {	flex-direction: row-reverse; }
.flex-col	{ flex-direction: column; }
.flex-col-reverse	{ flex-direction: column-reverse; }

// wrap
.flex-wrap	{ flex-wrap: wrap; }
.flex-wrap-reverse	{ flex-wrap: wrap-reverse; }
.flex-nowrap	{ flex-wrap: nowrap; }

// flex
.flex { display: flex; }
.flex-1	{ flex: 1 1 0%; }
.flex-auto	{ flex: 1 1 auto; }
.flex-initial	{ flex: 0 1 auto; }
.flex-none	{ flex: none; }

// grow
.grow	{ flex-grow: 1; }
.grow-0	{ flex-grow: 0; }

// shrink
.shrink	{ flex-shrink: 1; }
.shrink-0	{ flex-shrink: 0; }

// justify content
.justify-start	{ justify-content: flex-start; }
.justify-end	{ justify-content: flex-end; }
.justify-center	{ justify-content: center; }
.justify-between	{ justify-content: space-between; }
.justify-around	{ justify-content: space-around; }
.justify-evenly	{ justify-content: space-evenly; }

// justify items
.justify-items-start	{ justify-items: start; }
.justify-items-end	{ justify-items: end; }
.justify-items-center	{ justify-items: center; }
.justify-items-stretch	{ justify-items: stretch; }

// justify self
.justify-self-auto	{ justify-self: auto; }
.justify-self-start	{ justify-self: start; }
.justify-self-end	{ justify-self: end; }
.justify-self-center	{ justify-self: center; }
.justify-self-stretch	{ justify-self: stretch; }

// align content
.content-center	{ align-content: center; }
.content-start	{ align-content: flex-start; }
.content-end	{ align-content: flex-end; }
.content-between	{ align-content: space-between; }
.content-around	{ align-content: space-around; }
.content-evenly	{ align-content: space-evenly; }
.content-baseline	{ align-content: baseline; }

// align items
.items-start	{ align-items: flex-start; }
.items-end	{ align-items: flex-end; }
.items-center	{ align-items: center; }
.items-baseline	{ align-items: baseline; }
.items-stretch	{ align-items: stretch; }

// align-self
.self-auto	{ align-self: auto; }
.self-start	{ align-self: flex-start; }
.self-end	{ align-self: flex-end; }
.self-center	{ align-self: center; }
.self-stretch	{ align-self: stretch; }
.self-baseline	{ align-self: baseline; }