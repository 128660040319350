@import '../material/configs/colors';

.rounded {
  border-radius: 0.25rem;
}
.border-none {
  border-style: none !important;
}
.border-gray {
  border-color: lighten($black, 80%) !important;
}
