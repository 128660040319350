$white-color: #fff;
$light-gray: rgb(218, 218, 218);
$gray: lighten(rgb(102, 113, 125), 18%);
$red-color: rgb(205, 62, 71);
$black: rgb(34, 31, 32);
$dark-gray: rgb(88, 88, 88);
$action: rgb(80, 200, 120);
$blue-color: #4479b2;

$dark-primary-text: rgba($black, 0.87);
$dark-secondary-text: rgba($black, 0.54);
$dark-disabled-text: rgba($black, 0.38);
$dark-dividers: rgba($black, 0.12);
$dark-focused: rgba($black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);
