a,
button {
  &.mat-button-base {
    white-space: break-spaces !important;

    &[disabled] {
      cursor: not-allowed;
    }
  }
}
