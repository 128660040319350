// https://tailwindcss.com/docs/float

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.float-none {
    float: none;
}
