@use 'sass:map';
@use '@angular/material' as mat;
@include mat.core();
@import 'configs/themes';
@import 'configs/typography';

// material
// @import 'action-row';
@import 'mat-button';
@import 'mat-button-size';
@import 'mat-form-field';
@import 'table';
@import 'mat-drawer-backdrop';
@import 'mat-radio-button';

// @import 'mat-chip';
// @import 'mat-checkbox';
// @import 'mat-dialog';
// @import 'form-controls';
// @import 'mat-list';
// @import 'mat-select';
// @import 'mat-icon';
// @import 'slide-toggle';
// @import 'virtual-scroll-viewport';
// @import 'mat-paginator';
// @import 'mat-button-toggle';
// @import 'mat-button';
// @import 'mat-expansion-panel';
// @import 'typography';

/**
 * Colors
 */
@include mat.all-component-themes($primary-light-theme);

/**
 * Typography
 */
@include mat.all-component-typographies($evo-typography);
